:root {
  --lightGrey: #F2F2F2;
  --mediumGrey: #CDCBCC;
  --darkGrey: #999999;
}

body {
  --s: 84px;
  
  --_g: 0 120deg,#0000 0;
  background:
    conic-gradient(from    0deg at calc(500%/6) calc(100%/3),var(--darkGrey) var(--_g)),
    conic-gradient(from -120deg at calc(100%/6) calc(100%/3),var(--mediumGrey) var(--_g)),
    conic-gradient(from  120deg at calc(100%/3) calc(500%/6),var(--lightGrey) var(--_g)),
    conic-gradient(from  120deg at calc(200%/3) calc(500%/6),var(--lightGrey) var(--_g)),
    conic-gradient(from -180deg at calc(100%/3) 50%,var(--mediumGrey)  60deg,var(--lightGrey) var(--_g)),
    conic-gradient(from   60deg at calc(200%/3) 50%,var(--lightGrey)  60deg,var(--darkGrey) var(--_g)),
    conic-gradient(from  -60deg at 50% calc(100%/3),var(--lightGrey) 120deg,var(--mediumGrey) 0 240deg,var(--darkGrey) 0);
  background-size: calc(var(--s)*1.732) var(--s);
}

#btn-back-to-top{
  opacity: 0;
  transform: scale(0);
  visibility: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
}

#btn-back-to-top.visible {
  opacity: 1;
  transform: scale(1);
}

.card{
  max-width: 400px !important;
}

.hamburger-shell{
  min-height: 50px;
}

.hamburger-shell:focus{
  box-shadow: 0 0 0 var(--bs-success-rgb) !important;
}

.hamburger{
  width: 40px;
  height: 4px;
}

.hamburger-shell:hover .hamburger{
  background-color: var(--lightGrey) !important;
}

.display-image{
  max-width: 15rem !important;
  object-fit: contain !important;
}

.display-text{
  max-width: 50rem !important;
}